<div class="container text-center">
    <h1>Contact Us</h1>
    <p>Located in Sola Salon Studios</p>
</div>

<div class="container">
    <div class="row">
      <div class="col-sm">
        <h2>Phone</h2>
        <p>Kelsey: <a class="link-dark" href="tel:920-851-6184">(920) 851-6184</a></p>
        <p>Paige: <a class="link-dark" href="tel:920-850-9896">(920) 850-9896</a></p>
        <h2>Email</h2>
        <p><a class="link-dark" href="mailto:studiotwentytwobeautybar@gmail.com">studiotwentytwobeautybar@gmail.com</a></p>
        <h2>Address</h2>
        <Address class="mt-2">
          <a class="link-dark" href="https://www.google.com/maps/dir/44.2597376,-88.4342784/studio+twenty+two/@44.3864866,-88.5142822,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8802fbda8aa98401:0x487876082f822ce5!2m2!1d-88.0710664!2d44.4801507" target="_blank">Sola Salon Studios <br>
            2605 S Oneida St #2 <br>
            Green Bay, WI 54304
          </a>
        </Address>
        <h2>Bookings</h2>
        <p>To book an appointment please contact us via phone or <a class="link-dark" href="https://www.vagaro.com/studiotwentytwo3/book-now" target="_blank">Book Online</a></p>
      </div>
      <div class="col-sm">
        <div class="rounded" #mapContainer id="map"></div>
      </div>
    </div>
  </div>