import { Component, OnInit } from '@angular/core';
import { Service } from './models/Service.model';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  hairServices: Service[] = [];
  hairExtras: Service[] = [];

  constructor() { }

  ngOnInit(): void {
    this.buildServices();
  }

  buildServices(): void {
    this.hairServices = [
      {
        name: 'Lady\'s Cut',
        price: 30,
        priceText: '. . .$30+'
      },
      {
        name: 'Wash + Style',
        price: 35,
        priceText: '. . .$35'
      },
      {
        name: 'All Over Color',
        price: 120,
        priceText: '. . .$120'
      },
      {
        name: 'Balayage',
        price: 130,
        priceText: '. . .$130'
      },
      {
        name: 'Full Highlight',
        price: 130,
        priceText: '. . .$130'
      },
      {
        name: 'Root Touchup',
        price: 75,
        priceText: '. . .$75'
      },
      {
        name: 'Toner Refresh',
        price: 45,
        priceText: '. . .$45'
      },
      {
        name: 'Men\'s Cut',
        price: 25,
        priceText: '. . .$25'
      },
    ];

    this.hairExtras = [
      {
        name: 'Hair Treatment',
        price: 45,
        priceText: '. . .$45'
      },
      {
        name: 'Hair Mask',
        price: 15,
        priceText: '. . .$15'
      },
      {
        name: '+ Steam Infusion',
        price: 20,
        priceText: '. . .$20'
      },
      {
        name: 'Prom',
        price: 45,
        priceText: '. . .$45'
      },
      {
        name: 'Special Occasion',
        price: 65,
        priceText: '. . .$65'
      },
      {
        name: 'Kids Cut',
        price: 15,
        priceText: '. . .$15'
      },
      {
        name: 'Hair Extensions',
        price: 0,
        priceText: 'Consultation'
      }
    ];
  }

}
