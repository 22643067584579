<div class="container text-center">
    <h1>Services</h1>
    <p>We can't wait to be your GO-TO for all of your beauty needs.</p>
    <hr>
</div>

<div class="container background">
    <h2>Hair</h2>
    <p class="serviceDescription">Below you can find a list of our hair services</p>
    <ul class="servicesList">
        <li class="serviceItem" *ngFor="let hairService of hairServices;">
            {{hairService.name}}<span class="servicePrice">{{hairService.priceText}}</span>
        </li>
    </ul>
    <hr>
</div>

<div class="container background">
    <h2>Hair Extras</h2>
    <p class="serviceDescription">Add these must have extras to any of our hair services</p>
    <ul class="servicesList">
        <li class="serviceItem" *ngFor="let hairExtra of hairExtras;">
            {{hairExtra.name}}<span class="servicePrice">{{hairExtra.priceText}}</span>
        </li>
    </ul>
    <hr>
</div>