<div class="container text-center">
  <h1 class="mt-3 sub-heading alt-text-color">STUDIO TWENTY-TWO | BEAUTY BAR</h1>
  <h2 class="mt-3">Find Your Style</h2>
  <p class="alt-text-color">Allowing people to love their style and be confident in who they are.</p>
</div>
<div id="carouselExampleDark" class="carousel carousel-dark slide w-100 mx-auto" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true"
      aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner rounded">
    <div class="carousel-item active" data-bs-interval="5000">
      <img src="../../assets/images/studio_twenty_two_logo_padded.svg" class="d-block w-100 m-auto" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h5>First slide label</h5>
          <p>Some representative placeholder content for the first slide.</p> -->
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img
        src="../../assets/images/carousel_img_2.jpeg"
        class="d-block m-auto" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h5>Second slide label</h5>
          <p>Some representative placeholder content for the second slide.</p> -->
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img
        src="../../assets/images/carousel_img_3.jpeg"
        class="d-block m-auto" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h5>Third slide label</h5>
          <p>Some representative placeholder content for the third slide.</p> -->
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div class="container text-center">
  <h2 class="mt-3">WELCOME TO STUDIO TWENTY-TWO</h2>
  <p class="alt-text-color">We are salon professionals that care about the work we do. Our focus is making you confident
    and engaged with your style.</p>
  <a class="sub-link alt-text-color" routerLink="/artist">Learn More About Us <i
      class="fa-solid fa-arrow-right"></i></a>
</div>
<div class="container mt-3 text-center">
  <div class="row">
    <div class="col-sm">
      <div class="card border-0 text-center">
        <img src="../../assets/images/card_1_img_1.jpg" class="mx-auto w-75 img-thumbnail rounded-circle" alt="paige_profile">
        <div class="card-body">
          <h5 class="card-title">Hair Services</h5>
          <p class="card-text">We offer a wide variety of professional hair services. Come in for your next Cut, Style, Color, Baylage, or Highlight.</p>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card border-0">
        <img src="../../assets/images/card_2_img_1.jpg" class="mx-auto w-75 img-thumbnail rounded-circle" alt="kelsey_profile">
        <div class="card-body">
          <h5 class="card-title">Lashes</h5>
          <p class="card-text">Professional lash services. Our salon professionals will provide long lasting semi-permanent lashes to elevate your look.</p>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card border-0">
        <img src="../../assets/images/card_3_img_1.jpg" class="mx-auto w-75 img-thumbnail rounded-circle" alt="kelsey_profile">
        <div class="card-body">
          <h5 class="card-title">Events</h5>
          <p class="card-text">For your next event rely on Salon Twenty-Two. We can't wait to be apart of your special day.</p>
        </div>
      </div>
    </div>
  </div>
</div>
