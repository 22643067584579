<div class="container text-center">
  <h1>Our Beauty Artists</h1>
  <p>The people who make it all possible.</p>
</div>

<div class="container text-center">
  <div class="row">
    <div class="col-sm">
      <div class="card border-0">
        <img src="../../assets/images/paige_profile.jpg" class="card-img-top rounded-circle w-75 p-2 m-auto"
          alt="paige_profile">
        <div class="card-body">
          <h5 class="card-title">Paige</h5>
          <p class="card-text">Co owner of Studio Twenty-Two Beauty Bar - Hello friends and thank you for visiting our page! My name is Paige Schmidt, I graduated from The Salon Professional Academy in Appleton for cosmetology. My biggest belief in life is everyone should feel beautiful inside and out, and that is what I strive to achieve for every client that sits in my chair. I believe that continuing education is a huge part in this industry so I travel to many places to further my knowledge for all of my clients. I took a course in Chicago and I am Bellami certified in all types of hair extensions (volume wefts, hand tied wefts, keratin tips, i-tips, tape ins, and clip ins). I am also certified in lash lift & tints, brow lamination, and eyelash extensions (classic, hybrid, and volume).  I love to do all types of services but especially love all blonding services, all over colors, and hair extensions.  My dream since I was young was to open up a studio and I can't believe it has come true! Come visit me at Studio Twenty-Two and let's make all your beauty dreams come true!</p>
          <!-- <a href="https://www.instagram.com/mybeauty.paige/" class="btn btn-primary btn-dark">See my work</a> -->
          <ul class="list-group list-group-horizontal justify-content-center">
            <li class="list-group-item">
              <a class="link-dark" href="https://www.facebook.com/studiotwentytwobeautybar/"
                target="_blank"><i class="fa-brands fa-facebook"></i></a>
            </li>
            <li class="list-group-item">
              <a class="link-dark" href="https://www.instagram.com/studiotwentytwo_paige/" target="_blank"><i
                  class="fa-brands fa-instagram"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card border-0">
        <img src="../../assets/images/kelsey_profile.jpg" class="card-img-top rounded-circle w-75 p-2 m-auto"
          alt="kelsey_profile">
        <div class="card-body">
          <h5 class="card-title">Kelsey</h5>
          <p class="card-text">Co owner of Studio Twenty-Two Beauty Bar - hair extensions, blonde specialist, eyelash extensions and more</p>
          <!-- <a href="https://www.instagram.com/couture.esthetics.bykj/" class="btn btn-primary btn-dark">See my work</a> -->

          <ul class="list-group list-group-horizontal justify-content-center">
            <li class="list-group-item">
              <a class="link-dark" href="https://www.facebook.com/studiotwentytwobeautybar/"
                target="_blank"><i class="fa-brands fa-facebook"></i></a>
            </li>
            <li class="list-group-item">
              <a class="link-dark" href="https://www.instagram.com/studiotwentytwo_kelsey/" target="_blank"><i
                  class="fa-brands fa-instagram"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>