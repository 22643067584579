<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid w-75">
    <a class="navbar-brand" routerLink="/home"><img src="../../assets/images/studio_twenty_two_logo_alt.svg" alt="logo" width="200px"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor03"
      aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarColor03">
      <ul class="navbar-nav me-auto">
        <li class="nav-item active">
          <a class="nav-link dropdown-item" routerLink="/home" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/service" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/artist" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Artists</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true"
            aria-expanded="false">About</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" routerLink="/contact-us" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact</a>
            <a class="dropdown-item" href="https://www.google.com/search?q=studio+twenty+two&rlz=1C1ONGR_enUS936US936&sxsrf=ALiCzsYhPkAdL5M4vEM6UvgmM1bsTLL3gw%3A1669177492445&ei=lKB9Y7TVGpuaptQPkYWlwAY&oq=studio+&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAxgAMgQIIxAnMgQIIxAnMgQIIxAnMgQIABBDMgoILhDHARDRAxBDMg0ILhCxAxDHARDRAxBDMgsILhCABBDHARCvATIHCAAQyQMQQzIICAAQgAQQsQMyCwguEIAEEMcBEK8BOgUIABCRAjoLCAAQgAQQsQMQgwE6DgguEIAEELEDEMcBENEDOgUIABCABDoECC4QQzoKCAAQsQMQgwEQQzoKCAAQgAQQhwIQFDoRCC4QgAQQsQMQgwEQxwEQ0QM6BwguENQCEEM6CgguELEDENQCEEM6BQguEIAEOhEILhCABBCxAxCDARDHARCvAToHCAAQsQMQQzoLCC4QgAQQsQMQ1AI6CwguENQCELEDEIAEOhAIABCABBCHAhCxAxCDARAUOg0ILhDHARDRAxDUAhBDOggIABCxAxCDAUoECEEYAEoECEYYAFAAWLgQYLIWaARwAXgAgAGFAYgB-waSAQM0LjWYAQCgAQHAAQE&sclient=gws-wiz-serp#lrd=0x8802fbda8aa98401:0x487876082f822ce5,1,,," target="_blank">Reviews</a>
            <a class="dropdown-item" href="https://www.facebook.com/studiotwentytwobeautybar/" target="_blank">Our Story</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="https://www.facebook.com/studiotwentytwobeautybar/" target="_blank"><i
                class="fa-brands fa-facebook me-1"></i>Facebook</a>
            <a class="dropdown-item" href="https://www.instagram.com/studiotwentytwobeautybar/" target="_blank"><i
                class="fa-brands fa-instagram me-1"></i>Instagram</a>
          </div>
        </li>
      </ul>
      <a class="btn btn-dark" href="https://www.vagaro.com/studiotwentytwo3/book-now" target="_blank" role="button">Book Now</a>
      <!-- <ul class="navbar-nav" style="flex-direction: row;">
        <li class="nav-item">
          <a class="nav-link p-1" href="https://www.facebook.com/studiotwentytwobeautybar/" target="_blank"><i
              class="fa-brands fa-facebook"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link p-1" href="https://www.instagram.com/studiotwentytwobeautybar/" target="_blank"><i
              class="fa-brands fa-instagram"></i></a>
        </li>
      </ul> -->
    </div>
  </div>
</nav>